.dice-select-container .dice {
    position: relative;
    width: 6em;
    height: 6em;
    transform-style: preserve-3d;
    transition: transform 1s;
    cursor: pointer;
    border-radius: 5px;
    opacity: 0.5;
}

.dice-select-container .diceSelectionEnabled .dice:hover {
    opacity: 0.85;
}

.dice-select-container .dot {
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    margin: -0.75em 0.35em 0.75em -0.75em;
    border-radius: 2em;
    background-color: #f25f5c;
    box-shadow: inset 0.2em 0.2em #d90429;
}

/* .dice-one {
    position: absolute;
    left: 150px; 
  }
  
  .dice-two {
    position: absolute;
    left: -50px;
    
  } */

.dice-select-container .side {
    position: absolute;
    background-color: #ffF;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    border: 1px solid #e5e5e5;
    text-align: center;
    line-height: 2em;
}

.dice-select-container .dice.selected, .dice.selected .side {
    /* border: 2px solid hsl(var(--nextui-primary-500)); */
    /* background-color: hsl(var(--nextui-primary-500)); */
    opacity: 1.0;
}

.dice-select-container .side:nth-child(1) {
    transform: translateZ(3.1em);
}

.dice-select-container .side:nth-child(6) {
    transform: rotateY(90deg) translateZ(3.1em);
}

.dice-select-container .side:nth-child(3) {
    transform: rotateY(-90deg) translateZ(3.1em);
}

.dice-select-container .side:nth-child(4) {
    transform: rotateX(90deg) translateZ(3.1em);
}

.dice-select-container .side:nth-child(5) {
    transform: rotateX(-90deg) translateZ(3.1em);
}

.dice-select-container .side:nth-child(2) {
    transform: rotateY(-180deg) translateZ(3.1em);
}



.dice-select-container .two-1, .dice-select-container .three-1, .dice-select-container .four-1, .dice-select-container .five-1, .dice-select-container .six-1 {
    top: 20%;
    left: 20%;
}

.dice-select-container .four-3, .dice-select-container .five-3, .dice-select-container .six-4 {
    top: 20%;
    left: 80%;
}

.dice-select-container .one-1, .dice-select-container .three-2, .dice-select-container .five-5 {
    top: 50%;
    left: 50%;
}

.dice-select-container .four-2, .dice-select-container .five-2, .dice-select-container .six-3 {
    top: 80%;
    left: 20%;
}

.dice-select-container .two-2, .dice-select-container .three-3, .dice-select-container .four-4, .dice-select-container .five-4, .dice-select-container .six-6 {
    top: 80%;
    left: 80%;
}

.dice-select-container .six-2 {
    top: 50%;
    left: 20%;
}

.dice-select-container .six-5 {
    top: 50%;
    left: 80%;
}