@keyframes shrinkWidth {
    from {
        transform: scaleX(1);
    }

    to {
        transform: scaleX(0);
    }
}

@keyframes roundtime {
    from {
        width: 100%; 
        background-color: white;
    }
    to {
        /* transform: scaleX(0); */
        background-color: red;
        width: 0%; 
    }

}

@keyframes fireAnim{
    from {
        transform: scale(0.2);
        opacity: 0.0;
    }
    to {
        transform: scale(1.0);
        opacity: 1.0;

    }
}


.fireContainer{
    /* margin:1.333em auto; */
    width: 1em;
    height: 1em;
    /* position:relative; */
    transform-origin:center bottom;
    animation-name: flicker;
    animation-duration:3ms;
    animation-delay:200ms;
    animation-timing-function: ease-in;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
  
  .flame{
    bottom:0;
    position:absolute;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    transform:rotate(-45deg) scale(1.5,1.5);
  }
  
  .yellow{
    left:0.25em; 
    width: 0.5em;
    height: 0.5em;
    background:gold;
    box-shadow: 0em 0em 0.15em 0.0667em gold;
  }
  
  .orange{
    left:0.1667em; 
    width: 0.6667em;
    height: 0.6667em;
    background:orange;
    box-shadow: 0em 0em 0.15em 0.0667em orange;
  }
  
  .red{
    left:0.0833em;
    width: 0.8333em;
    height: 0.8333em;
    background:OrangeRed;
    box-shadow: 0em 0em 0.0833em 0.0667em OrangeRed;
  }
  
  .white{
    left:0.25em; 
    bottom:-0.0667em;
    width: 0.5em;
    height: 0.5em;
    background:white;
    box-shadow: 0em 0em 0.15em 0.0667em white;
  }
  
  .circle{
    border-radius: 50%;
    position:absolute;  
  }
  
  .blue{
    width: 0.1667em;
    height: 0.1667em;
    left:0.4167em;
    bottom:-0.4167em; 
    background: SlateBlue;
    box-shadow: 0em 0em 0.25em 0.1667em SlateBlue;
  }
  
  .black{
    width: 0.6667em;
    height: 0.6667em;
    left:0.1667em;
    bottom:-1em;  
    background: black;
    box-shadow: 0em 0em 0.25em 0.1667em black;
  }
  
  @keyframes flicker{
    0%   {transform: rotate(-5deg);}
    20%  {transform: rotate(5deg);}
    40%  {transform: rotate(-5deg);}
    60%  {transform: rotate(5deg) scaleY(1.14);}
    80%  {transform: rotate(-8deg) scaleY(0.92);}
    100% {transform: rotate(5deg);}
  }
  /* @keyframes flicker{
    0%   {transform: rotate(-1deg);}
    20%  {transform: rotate(1deg);}
    40%  {transform: rotate(-1deg);}
    60%  {transform: rotate(1deg) scaleY(1.04);}
    80%  {transform: rotate(-2deg) scaleY(0.92);}
    100% {transform: rotate(1deg);}
  } */