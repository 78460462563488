/* .page {
  text-align: center;
  text-transform: uppercase;
  background: lightskyblue;
  color: white;
  height: 40%;
} */

/* .v-container {
  display: flex;
  flex-direction: column;
} */

/* .h-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
} */

/* .h-container__col {
  margin: 0 10px;
} */

/* .brief {
  font-size: 12px;
  color: black;
  text-align: left;
  list-style-type: none;
  padding: 0;
} */

/* .ship-list {
  text-align: center;
  margin: auto;
  width: 320px;
} */

/* .ship-list__row {
  display: flex;
} */

/* .ship-list__row__label {
  min-width: 90px;
  font-size: 12px;
  font-weight: bold;
  line-height: 40px;
  text-align: left;
} */

/* .board-title {
  font-wight: bold;
  font-size: 14px;
  margin-bottom: 8px;
} */

/* .board-title--current {
  color: blue;
} */

/* .board {
  display: flex;
  flex-flow: row wrap;
  width: 320px;
  min-width: 320px;
  margin: 0 auto;
  border: 1px solid black;
  background: white;
} */

.cell {
  /* cursor: pointer; */
  /* color: seagreen; */
  /* background: white; */
  border: 1px dotted rgba(172, 171, 171, 0.8);
  min-width: 2em;
  min-height: 2em;
  max-width: 2em;
  max-height: 2em;
}

.cell--ship {
  border: 1px solid black;
  /* background: white; */

}

.cell--damaged {
  border: 1px solid black;
  /* background: url('data:image/svg+xml;charset=utf8,<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><g><line x1="10" y1="0" x2="0" y2="10" stroke="red" stroke-width="1" /><line x1="0" y1="0" x2="10" y2="10" stroke="red" stroke-width="1" /></g></svg>') center center / 15px 15px no-repeat; */
}

.cell--inactive {
  /* background: url('data:image/svg+xml;charset=utf8,<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><g><circle r="30" cx="0" cy="0" fill="black" /></g></svg>') center center / 3px 3px no-repeat; */
  background-image:
    linear-gradient(45deg,
      rgb(230, 230, 230, 0.0) 25%,
      rgba(201, 201, 201, 0.7)  25%,
      rgba(201, 201, 201, 0.7)  50%,
      rgb(230, 230, 230, 0.0) 50%,
      rgb(230, 230, 230, 0.0) 75%,
      rgba(201, 201, 201, 0.7)  75%,
      rgba(201, 201, 201, 0.7) 100%);
  background-size: 33.3% 33.3%;
}

/* .cell--open {
  background: url('data:image/svg+xml;charset=utf8,<svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg"><g><line x1="10" y1="0" x2="0" y2="10" stroke="silver" stroke-width="1" /><line x1="0" y1="0" x2="10" y2="10" stroke="silver" stroke-width="1" /></g></svg>') center center / 15px 15px no-repeat;
} */

.ship {
  display: flex;
  /* margin: 4px 8px; */
  touch-action: none;
  margin: -2px;
  border: 1px solid black;

}

.ship--current {
  position: absolute;
}

.ship--current.ship--vertical {
  flex-flow: column wrap;
}