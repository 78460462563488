
.battleInfo{
    font-family: "Barmo";
}

.battleInfo .name {}

.battleInfo .healthTextLeft {
    text-shadow: 0 0 2px #00ff00;
}

.battleInfo .healthTextRight {
    text-shadow: 0 0 1px var(--nextui-primary);

}


.battleAnim .battleInfo {
    opacity: 0;
    animation: slam-down-front 0.4s ease-out both;
    font-family: "Barmo";
}



.swordLeftAttack {
    transform: translateX(0) rotate(0deg);
    animation: sword-left-attack 0.6s ease both;
}

.swordRightAttack {
    transform: translateX(0) rotate(0deg);
    animation: sword-right-attack 0.6s ease both;
}

@keyframes sword-left-attack {
    0% {
        z-index: 2;
        transform: translateX(0) rotate(0deg);
    }
    50% {
        z-index: 2;
        transform: translateX(60px) rotate(60deg);
    }
    100% {
        z-index: 2;
        transform: translateX(0) rotate(0deg);
    }
}

@keyframes sword-right-attack {
    0% {
        z-index: 2;
        transform: translateX(0) rotate(0deg);
    }
    50% {
        z-index: 2;
        transform: translateX(-60px) rotate(-60deg);
    }
    100% {
        z-index: 2;
        transform: translateX(0) rotate(0deg);
    }
}

.slam-down-front{
    animation: slam-down-front 0.8s ease-out;
}

@keyframes slam-down-front {
    0% {
        transform: scale(5);
        opacity: 0;
    }

    60% {
        transform: scale(1);
        opacity: 0.5;
    }

    80% {
        transform: scale(1.3);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 1;

    }
}