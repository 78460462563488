@tailwind base;
@tailwind components;
@tailwind utilities;


/* @font-face {
  font-family: "Barmo";
  src: url(./fonts/Barmo.otf) format('otf') url(./fonts/Barmo.woff) format('woff');
}

@font-face {
  font-family: "Poppins";
  src: url(./fonts/Poppins-Regular.ttf) format('ttf') url(./fonts/Poppins-Regular.woff) format('woff');
}

@font-face {
  font-family: "Poppins-Light";
  src: url(./fonts/Poppins-Light.ttf) format('ttf') url(./fonts/Poppins-Light.woff) format('woff');
} */

/* @font-face {
  font-family: Barmo;
  src: local('Barmo'), url(./fonts/Barmo.otf) format('otf');
}

@font-face {
  font-family: Poppins;
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('ttf');
}

@font-face {
  font-family: Poppins-Light;
  src: local('Poppins-Light'), url(./fonts/Poppins-Light.ttf) format('ttf');
} */

/* @font-face {
  font-family: Crony;
  src: local('Crony'), url(./fonts/Crony.ttf) format('ttf');
} */


.gradientBg {
  background-attachment: fixed;
  background: rgb(50 16 93);
  background: linear-gradient(150deg, rgb(50 16 93) 0%, rgb(66, 82, 155) 50%, rgb(42, 138, 58) 100%)
}


body {
  font-family: "Poppins-Light", Poppins-Light, "DM Sans", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  overflow-x: hidden;
  overscroll-behavior: none;
}


.logoFlip {
  -webkit-transition: transform 0.5s ease;
  -moz-transition: transform 0.5s ease;
  -ms-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
}

.logoFlip:hover {
  transform: translateX(-1px) rotateY(180deg);
  /* ALSO EXTRA TRANSFORM PROPERTIES ADDED FOR COMPATIBILITY*/
  -ms-transform: translateX(-1px) rotateY(180deg);
  /* IE 9 */
  -webkit-transform: translateX(-1px) rotateY(180deg);
  /* Chrome, Safari, Opera */
}


.no-hover-opacity:hover {
  opacity: 1 !important;
}

.z-3{
  z-index: 3;
}



/* battles and lotteries, when amount changes */
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes blinkBg {
  0% { background-color: rgba(255, 255, 255, 0.1); }
  20% { background-color: rgba(255, 255, 255, 0.8); }
  30% { background-color: rgba(255, 255, 255, 0.1); }
  50% { background-color: rgba(255, 255, 255, 0.8); }
  70% { background-color: rgba(255, 255, 255, 0.1); }
  90% { background-color: rgba(255, 255, 255, 0.8); }
  100% { background-color: rgba(255, 255, 255, 0.1); }
}

.shake {
  animation: shake 0.3s;
  animation-iteration-count: infinite;
}

.shake1500 {
  animation: shake 1.5s;
  animation-iteration-count: infinite;
}

.blinkBg1500{
  animation: blinkBg 1.5s;
  animation-iteration-count: infinite;

}

.amountChangeNotifier{
  /* font-size: 1.5em; */
  /* color: rgb(0, 196, 33); */
  /* font-weight: bold; */
  z-index: 50;
}

@keyframes disappear {
  0% { opacity: 1; transform: translateY(0); }
  50% { opacity: 1}
  100% { opacity: 0; transform: translateY(-50px); }
}

.disappear {
  animation: disappear 1s forwards;
}


.yellowGlow {
  animation: yellowGlow 0.3s ease-in-out infinite;
}
@keyframes yellowGlow {
  0% {
    filter: drop-shadow(0 0 0px rgba(255, 238, 106, 0.0));
  }
  50% {
    filter: drop-shadow(0 0 12px rgba(255, 238, 106, 0.75));
  }
  100% {
    filter: drop-shadow(0 0 0px rgba(255, 238, 106, 0.0));
  }
}


/* 
.App {
  text-align: center;
}

.App-logo {
  height: 2cm;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}