.wallet-adapter-dropdown {
  height: auto;
  min-height: auto;
  /* 100%; */
}

.wallet-adapter-dropdown .wallet-adapter-button-trigger {
  background-color: rgba(255, 255, 255, 0.1);
  font-size: 1.2rem;
}

.headerWallet .wallet-adapter-dropdown button {
  font-size: 0.8rem !important;
  padding: 8px;
  /* height: auto; */
  /* background-color: initial; */
  background-color: rgba(255, 255, 255, 0.1);
}

.headerWallet .wallet-adapter-dropdown button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.headerWallet .wallet-adapter-button{
  /* line-height: 24px; */
}


.wallet-adapter-button.nicer {
  /* These styles should have higher specificity and override the defaults */
  background-color: var(--nextui-primary-500);
  ;

}

.wallet-adapter-button-trigger.nicer {
  background-color: var(--nextui-primary-500);
  ;
}