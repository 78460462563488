.rotatingCoin {
  border-radius: 100%;
  height: 10em;
  /* margin: 5em auto; */
  position: relative;
  width: 10em;
  /* transition: .5s linear; */
  /* transition: transform 1s linear; */
  transform-style: preserve-3d;
  /* animation: coinFlip 8s infinite linear; */
  transform-origin: 50% 50%;
}

@keyframes coinFlip {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}

@keyframes coinToHeads {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(0deg);
  }
}

@keyframes coinToTails {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(180deg);
  }
}

.rotatingCoin .front, .rotatingCoin .back {
  border-radius: 100%;
  height: 123%;
  width: 123%;
  margin-left: -11.5%;
  position: absolute;
  top: -11.55%;
}

.rotatingCoin .front {
  /* background-image: url("/img/logo.png"); */
  background-size: 100% 100%;
  transform: translateZ(0.5em);

}

.rotatingCoin .back {
  /* background-image: url("/img/logo.png"); */
  background-size: 100% 100%;
  transform: rotateY(180deg) translateZ(0.5em);
  text-shadow: 0em 0.0625em 0em #fff, 0em -0.0625em 0em #000;
}



.rotatingCoin .coinFlipSide {
  /* -webkit-transform: translateX(  4.5em)  translateY(0.1em) translateZ(-0.5em); */
  transform: translateX(4.5em) translateY(0.1em) translateZ(0em);

  -webkit-transform-style: preserve-3d;
  /* -webkit-backface-visibility: hidden; */
}

.rotatingCoin .coinFlipSide .spoke {
  height: 9.7em;
  width: 1em;
  position: absolute;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.rotatingCoin .coinFlipSide .spoke:before,
.rotatingCoin .coinFlipSide .spoke:after {
  content: '';
  display: block;
  height: 2em;
  /* 0.980171403em; */
  width: 1em;
  position: absolute;
  /* -webkit-transform: rotateX(84.375deg); */
  -webkit-transform: rotateX(79.8deg);
  background: hsl(249, 60%, 14%);
  background: linear-gradient(to bottom, hsl(286, 80%, 16%) 0%, hsl(261, 94%, 33%) 74%, hsl(295, 81%, 28%) 100%);
  background-size: 100% 0.2em;
}

.rotatingCoin .coinFlipSide .spoke:before {
  -webkit-transform-origin: top center;
}

.rotatingCoin .coinFlipSide .spoke:after {
  bottom: 0;
  -webkit-transform-origin: center bottom;
}




.rotatingCoin .coinFlipSide .spoke:nth-child(8) {
  -webkit-transform: rotateY(90deg) rotateX(180deg);
}

.rotatingCoin .coinFlipSide .spoke:nth-child(7) {
  -webkit-transform: rotateY(90deg) rotateX(157.5deg);
}

.rotatingCoin .coinFlipSide .spoke:nth-child(6) {
  -webkit-transform: rotateY(90deg) rotateX(135deg);
}

.rotatingCoin .coinFlipSide .spoke:nth-child(5) {
  -webkit-transform: rotateY(90deg) rotateX(112.5deg);
}

.rotatingCoin .coinFlipSide .spoke:nth-child(4) {
  -webkit-transform: rotateY(90deg) rotateX(90deg);
}

.rotatingCoin .coinFlipSide .spoke:nth-child(3) {
  -webkit-transform: rotateY(90deg) rotateX(67.5deg);
}

.rotatingCoin .coinFlipSide .spoke:nth-child(2) {
  -webkit-transform: rotateY(90deg) rotateX(45deg);
}

.rotatingCoin .coinFlipSide .spoke:nth-child(1) {
  -webkit-transform: rotateY(90deg) rotateX(22.5deg);
}


.coin.skeleton .front,
.coin.skeleton .back {
  display: none;
}

.coin.skeleton .coinFlipSide,
.coin.skeleton .coinFlipSide .spoke,
.coin.skeleton .coinFlipSide .spoke:before,
.coin.skeleton .coinFlipSide .spoke:after {
  -webkit-backface-visibility: visible;
}

.coin.skeleton .coinFlipSide .spoke {
  background: rgba(170, 170, 170, 0.1);
}

.coin.skeleton .coinFlipSide .spoke:before {
  background: rgba(255, 170, 170, 0.2);
}

.coin.skeleton .coinFlipSide .spoke:after {
  background: rgba(204, 204, 255, 0.2);
}